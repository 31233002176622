import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject, Observable, Subscription } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { environment } from 'src/environments/environment';

type InsuranceType = "DL" | "MI";

@Component({
  selector: 'app-scan-photo',
  templateUrl: './scan-photo.component.html',
  styleUrls: ['./scan-photo.component.scss'],
})
export class ScanPhotoComponent implements OnInit {
  @Input() height = "300";
  @Input() width = "500";
  @Input() type: InsuranceType = "MI";
  @Input() isStoreData: boolean = false;
  @Output() onUploadData = new EventEmitter();
  // toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = false;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot file
  public webcamImage: File = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  private eventsSubscription: Subscription;
  @Input() events: Observable<void>;

  ngOnInit() {
    this.eventsSubscription = this.events.subscribe(() => this.triggerSnapshot());

    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public handleImage(webcamImage: WebcamImage): void {
    // Get image & conver Webcam Image to File object
    const arr = webcamImage.imageAsDataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    this.webcamImage = new File([u8arr], "DrivingInsurance", { type: "image/*" })
    console.log("this.webcamImage================", this.webcamImage)
    // Validate that file object image from Nanonet server
    this.handleNanonetsDrivingLicenceValidation(this.webcamImage);
    // this.onUploadData.emit(this.webcamImage);
  }

  public dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  public handleNanonetsDrivingLicenceValidation(fileBLob) {
    let _this = this;
    var data = new FormData();
    data.append('file', fileBLob); // This is file object
    // var data = 'urls=https://goo.gl/ICoiHc'; // This is Url string
    var xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", function (data) {
      if (this.readyState === this.DONE) {
        console.log(this.responseText);
        let data: any = this.responseText;
        if (this.responseText && typeof this.responseText == "string") {
          data = JSON.parse(this.responseText)
        }
        if (typeof data == "object" && data?.result && Array.isArray(data?.result)) {
          let urlLabel = data?.result[0]?.filepath;
          if(_this.isStoreData && _this.type == "DL"){
            localStorage.setItem("KIOSK@DL", JSON.stringify(data));
          }
          let originalImageUrl = data?.signed_urls[urlLabel]?.original;
          let originalCompressedImageUrl = data?.signed_urls[urlLabel]?.original_compressed;
          _this.onUploadData.emit({originalImageUrl, originalCompressedImageUrl});
        }
      }
    });

    xhr.open("POST", `https://app.nanonets.com/api/v2/OCR/Model/${environment.nanonetModelID}/LabelFile/?async=false`);
    // xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded"); // This is Url string
    xhr.setRequestHeader("authorization", "Basic " + btoa(environment.nanonetAPIKey));
    xhr.send(data);
  }
}

