import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { NgOtpInputModule } from  'ng-otp-input';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import {WebcamModule} from 'ngx-webcam';

import { ScanPhotoComponent } from 'src/app/components/scan-photo/scan-photo.component';
import { ScanLicenseComponent } from './modals/scan-license/scan-license.component';
import { ScanInsuranceComponent } from './modals/scan-insurance/scan-insurance.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, ScanLicenseComponent, ScanPhotoComponent, ScanInsuranceComponent],
  imports: [
    BrowserModule, 
    MatDialogModule,
    // NgOtpInputModule,
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    WebcamModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
  entryComponents: [ScanLicenseComponent, ScanPhotoComponent, ScanInsuranceComponent]
})
export class AppModule {}
