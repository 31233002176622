import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'set-language',
    loadChildren: () => import('./pages/set-language/set-language.module').then( m => m.SetLanguagePageModule)
  },
  {
    path: 'agreement',
    loadChildren: () => import('./pages/agreement/agreement.module').then( m => m.AgreementPageModule)
  },
  {
    path: 'prerequisite',
    loadChildren: () => import('./pages/prerequisite-doc/prerequisite.module').then( m => m.PrerequisitePageModule)
  },
  {
    path: 'scan-license',
    loadChildren: () => import('./pages/scan-license/scan-license.module').then( m => m.ScanLicensePageModule)
  },
  {
    path: 'insurance-card',
    loadChildren: () => import('./pages/insurance-card/insurance-card.module').then( m => m.InsuranceCardPageModule)
  },
  {
    path: 'personal-information',
    loadChildren: () => import('./pages/personal-information/personal-information.module').then( m => m.PersonalInformationPageModule)
  },
  {
    path: 'consent-forms',
    loadChildren: () => import('./pages/consent-forms/consent-forms.module').then( m => m.ConsentFormsPageModule)
  },
  {
    path: 'review-and-complete',
    loadChildren: () => import('./pages/review-and-complete/review-and-complete.module').then( m => m.ReviewAndCompletePageModule)
  },
  {
    path: 'thank-you',
    loadChildren: () => import('./pages/thank-you/thank-you.module').then( m => m.ThankYouPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
