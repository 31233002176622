
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private idleScreen = new Subject<boolean>();

  constructor() {
    this.idleScreen.next(false);
  }

  setIdleScreen(isIdle: boolean) {
    this.idleScreen.next(isIdle);
  }

  getIdleScreen(): Observable<boolean> {
    return this.idleScreen.asObservable();
  }
}
