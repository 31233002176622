import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig} from "@angular/material/dialog";
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-scan-license',
  templateUrl: './scan-license.component.html',
  styleUrls: ['./scan-license.component.scss'],
})
export class ScanLicenseComponent implements OnInit {
  public captureSubject: Subject<void> = new Subject<void>();
  public currentImage = null;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private dialogRef: MatDialogRef<ScanLicenseComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { }

  ngOnInit() {}

  closeModal(){
    this.dialogRef.close("close");
  }

  clickLicenseOpen(){
    // this.router.navigateByUrl("/scan-license");
    this.dialogRef.close(this.currentImage?.originalImageUrl);
  }

  onClickRetake(){
    this.currentImage = null;
    this.captureSubject.next();
  }

  onClickContinue(){
    this.captureSubject.next();
  }

  onScanData(data){
    this.currentImage = data;
  }
  
}