import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import { AppService } from './services/app.service';
import { TimerComponent } from 'src/app/modals/timer/timer.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  // some fields to store our state so we can display it in the UI
  countdown?: number = null;
  lastPing?: Date = null;

  // add parameters for Idle and Keepalive (if using) so Angular will inject them from the module
  constructor(
    private idle: Idle, 
    keepalive: Keepalive, 
    cd: ChangeDetectorRef,
    public translate: TranslateService, 
    private platform: Platform,
    private router: Router,
    private appService: AppService,
    private dialog: MatDialog
  ) {
    // set idle parameters
    idle.setIdle(45); // how long can they be inactive before considered idle, in seconds
    idle.setTimeout(30); // how long can they be idle before considered timed out, in seconds
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    idle.onIdleStart.subscribe(() => {
      // open modal
      this.closeDialog();
      this.openTimerDialog();
    });
    // do something when the user is no longer idle
    idle.onIdleEnd.subscribe(() => {
      this.countdown = null;
      cd.detectChanges(); // how do i avoid this kludge?
    });
    // do something when the user has timed out
    idle.onTimeout.subscribe(() => {
      // Hide modal
      this.closeDialog();
      this.router.navigate(['/welcome']);
      this.appService.setIdleScreen(false);
    });
    // do something as the timeout countdown does its thing
    idle.onTimeoutWarning.subscribe(seconds => {
      this.countdown = seconds;
    });

    // set keepalive parameters, omit if not using keepalive
    keepalive.interval(15); // will ping at this interval while not idle, in seconds
    keepalive.onPing.subscribe(() => this.lastPing = new Date()); // do something when it pings
    
    this.appService.getIdleScreen().subscribe(isIdle => {
      if (isIdle) {
        this.reset();
      } else {
        idle.stop();
      }
    })

    this.initializeApp();
  }
  
  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.countdown = null;
    this.lastPing = null;
  }

  ngOnInit(): void {
    // if(window.location.pathname != "/welcome" && window.location.pathname != "/"){
    //   this.appService.setIdleScreen(true);
    // }
  }

  openTimerDialog() {
    let dialogRef = this.dialog.open(TimerComponent, {
      panelClass: 'timerModal',
      width: '80%',
      height: '80%',
      position: {top: '100px'},
      data: {
      }
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.router.navigate(['/welcome']);
        this.appService.setIdleScreen(false);
      }else{
        if(result != undefined){
          this.reset();
        }
      }
    });
  }
  
  closeDialog(){
    this.dialog.closeAll();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.resetApp();
    });
  }

  resetApp(){
    this.translateDefaultConfig();
  }

  translateDefaultConfig() {
    this.translate.setDefaultLang('en');
  }
}
