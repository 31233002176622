// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
    // nanonetAPIKey: "Vs4H5JLgHXTWpTNH-gmzCKaCZLHjuVJa:",
  // nanonetModelID: "efcf223a-e98d-4316-94a3-d995043ed32d"
  nanonetAPIKey: "O3--dsLiMjmyELA-dJ64c3P7nJsommb_:",
  nanonetModelID: "d6eff83b-2dbf-4b99-9ee9-602509900f55"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
