import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig} from "@angular/material/dialog";
import { Router } from '@angular/router';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private dialogRef: MatDialogRef<TimerComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.closeModal(true);
    }, 31000);
  }

  closeModal(isClose = false){
    this.dialogRef.close(isClose);
  }
}