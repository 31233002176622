import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig} from "@angular/material/dialog";
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-scan-insurance',
  templateUrl: './scan-insurance.component.html',
  styleUrls: ['./scan-insurance.component.scss'],
})
export class ScanInsuranceComponent implements OnInit {
  public type: string = "";
  public captureSubject: Subject<void> = new Subject<void>();
  public currentImage = null;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private dialogRef: MatDialogRef<ScanInsuranceComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    if(data && data?.type){
      this.type = data?.type;
    }
  }

  ngOnInit() {}

  closeModal(){
    this.dialogRef.close("close");
  }

  clickLicenseOpen(){
    this.dialogRef.close(this.currentImage?.originalImageUrl);
    this.currentImage = null;
  }

  onClickRetake(){
    this.currentImage = null;
    this.captureSubject.next();
  }

  onClickContinue(){
    this.captureSubject.next();
  }

  onScanData(data){
    this.currentImage = data;
  }
}
